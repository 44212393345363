<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="$vuetify.breakpoint.mobile"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="secondary"
        style="width: 50%"
        class="py-6 rounded-lg rounded-l-0"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>mdi-menu</v-icon> Categorías
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="justify-center"
        >
        Listado de categorías
        <v-btn
          style="position: absolute; right: 12px"
          icon
          @click="dialog = false"
          >
          <v-icon
            >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          style="max-width: 1200px"
          class="mx-auto py-3 d-flex flex-wrap"
          >
          <v-dialog
            width="600"
            v-model="modal"
            :fullscreen="$vuetify.breakpoint.mobile"
            >
            <category
              @close="modal = false"
              v-if="selected"
              :key="selected.slug"
              :category="selected"
              :store="store"
              ></category>
          </v-dialog>

          <template
            v-for="category in categories"
            >
            <div
              class="px-3 pb-3 body-1 fill-width"
              >
              <div
                class="py-1 secondary--text font-weight-medium"
                style="cursor: pointer"
                @click="redirect(category)"
                >
                {{ category.name }}
              </div>
              <v-divider></v-divider>
              <template
                v-for="child in category.children"
                >
                <div
                  :key="child.permalink"
                  class="py-1 secondary--text"
                  style="cursor: pointer"
                  @click="redirect(child)"
                  >
                  <v-icon x-small>mdi-chevron-right</v-icon> {{ child.name }}
                </div>
                <template
                  v-for="child2 in child.children"
                  >
                  <div
                    class="py-1 secondary--text"
                    style="cursor: pointer"
                    @click="redirect(child2)"
                    >
                    <v-icon
                      v-for="i in 2"
                      :key="'icon-' + i"
                      x-small>mdi-chevron-right</v-icon> {{ child2.name }}
                  </div>
                </template>
              </template>
            </div>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CategoriesMenu } from '@/graphql/queries/categories'
import { mapGetters } from 'vuex'

const Category = () => import('@/components/categories/Category')

export default {
  data () {
    return {
      categories: [],
      dialog: false,
      modal: false,
      selected: null
    }
  },

  props: {
    store: {
      required: true,
      type: Object
    }
  },

  created () {
    this.fetchCategories(this.store.id)

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        if (this.modal) {
          this.modal = false
        } else {
          this.dialog = false
        }

        next(false)
      } else {
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },

  methods: {
    fetchCategories (id) {
      this.$apollo.query({
        query: CategoriesMenu,
        variables: {
          onlyRoot: true,                           
          storeId: id                               
        }                                           
      }).then ( res => {                            
        let categories = res.data.categoriesMenu    
        this.categories = categories
                                                    
        if (categories.length) {                    
          let hs = {                                
            name: 'Categorías',                     
            type: 'taxon',                          
          }                                         
        }                                           
      })                                            
    },                                              
                                                    
    redirect (category) {                          
      this.selected = category
      this.modal = true
    }                                               
  },
  
  components: {
    Category
  }
}                                                   
</script>                                           
